<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingInvestorApproval',
  components: {
    VideoIdent: lazyLoad('views/Onboarding/VideoIdent/Selection'),
    MinimumDeposit: lazyLoad('views/Onboarding/InvestorApproval/MinimumDeposit'),
  },
  data() {
    return {
      getUserInterval: null,
    };
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    component() {
      return this.$route.params.step;
    },
  },
  watch: {
    subStage(step) {
      if (step !== 'completed') {
        this.$router.push({
          name: 'Onboarding.InvestorApproval',
          params: {
            step,
          },
        });
      }
    },
  },
  created() {
    this.fetchNewData();
    this.getUserInterval = setInterval(() => {
      this.fetchNewData();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.getUserInterval);
  },
  methods: {
    ...mapActions('user', [
      'updateOnboarding',
      'setMainStage',
      'setSubStage',
      'setOnBoardingCompleted',
      'getBalance',
      'updateUser',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async submitForm() {
      try {
        await this.$refs.form.submit();
      } catch (error) {
        throw new Error(error);
      }
    },
    async fetchNewData() {
      await this.getBalance();
      await this.$store.dispatch('user/getUser', null, { root: true });
      if (this.mainStage === 'completed' && this.subStage === 'completed') {
        this.$router.push({
          name: 'Onboarding.Overview',
        });
      }
    },
    async next() {
      this.setLoading(true);
      if (this.component === 'VideoIdent') {
        const input = {
          investor: {},
          mainStage: 'investorApproval',
          subStage: 'videoIdent',
        };
        await this.updateOnboarding({
          input,
        });
        this.setLoading(false);
        return this.setSubStage('minimumDeposit');
      }
      const input = {
        investor: {},
        mainStage: 'investorApproval',
        subStage: 'minimumDeposit',
      };
      await this.updateOnboarding({
        input,
      });
      this.setOnBoardingCompleted(true);
      this.setSubStage('completed');
      this.setMainStage('completed');
      this.setLoading(false);
      return this.$router.push({
        name: 'Onboarding.Overview',
      });
    },
  },
};
</script>
